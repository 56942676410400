<template>
	<div class="login">
		<div class="login-left">
			<img @click="requesthome()" src="../../assets/img/common/b_logo.png" class="logo" />
			<img src="../../assets/img/login/lgoin_bg.png" class="login-bg" />
		</div>
		<div class="login-right">
			<div class="right-form">
				<div class="welcome">欢迎登录教师端</div>
				<el-form ref="loginForm" :model="loginForm" :rules="loginRules" autocomplete="on" label-position="left">
					<el-form-item prop="username">
						<el-input placeholder="请输入账号名" v-model="loginForm.username" ref="username" name="username"
							size="medium" class="mb-14"></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input class="mb-14" v-model="loginForm.password" size="medium" ref="password" name="password"
							placeholder="请输入密码" show-password></el-input>
					</el-form-item>

					<el-form-item prop="verifycode">
						<el-input style="width: 50%;" v-model="loginForm.verifycode" placeholder="请输入验证码" size="medium"
							class="identifyinput"></el-input>
						<div @click="refreshCode" style="width:40%; float:right; inline-size: auto;">
							<s-identify :identifyCode="identifyCode"></s-identify>
						</div>
					</el-form-item>
					<div class="justify-between">
						<div class="day">
							<el-checkbox v-model="checked"></el-checkbox>
							<span class="ml-4">记住密码</span>
						</div>
					</div>
					<div class="btn cursor-p" :loading="loading" ref="loginForm" @click="checkLogin"
						@keyup.enter="keyDown(e)">登录
					</div>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
import {
	login
} from '@/api/demo'
import {
	Message,
	MessageBox
} from 'element-ui' //导入element-ui组件库
import {
	getToken,
	setToken,
	removeToken
} from '@/utils/cookies.js'

import SIdentify from '@/components/identify'
const Base64 = require('js-base64').Base64
export default {
	name: 'Login',
	data() {
		// 验证码自定义验证规则
		const validateVerifycode = (rule, value, callback) => {
			if (!value) {
				callback(new Error('请输入验证码'))
			} else if (value.toUpperCase() !== this.identifyCode.toUpperCase()) {
				callback(new Error('验证码不正确!'))
				this.refreshCode();
			} else {
				callback()
			}
		}
		return {
			loginForm: {
				username: '',
				password: ''
			},
			identifyCodes: '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ',
			identifyCode: '',
			loginRules: {
				username: [{
					required: true,
					trigger: 'blur',
					message: '请输入用户名',
					trigger: 'blur'
				}],
				password: [{
					required: true,
					trigger: 'blur',
					message: '请输入密码',
					trigger: 'blur'
				}],
				verifycode: [{
					required: true,
					trigger: 'blur',
					validator: validateVerifycode
				}]
			},
			loading: false,
			checked: false
		};
	},
	watch: {
		$route: {
			handler: function (route) {
				const query = route.query
				if (query) {
					this.redirect = query.redirect
				}
			},
			immediate: true
		}
	},
	components: {
		SIdentify
	},
	created() {
		//甭管三七二十一，先把存储的token删了在说	
		removeToken();
		let hostname = window.location.hostname;
		hostname = hostname.replace("www", "");
		setToken('', {
			expires: 1,
			path: '',
			domain: hostname
		});
		
		// 在页面加载时从cookie获取登录信息
		let username = this.getCookie("username")
		let password = Base64.decode(this.getCookie("password"))
		// 如果存在赋值给表单，并且将记住密码勾选
		if (username) {
			this.loginForm.username = username
			this.loginForm.password = password
			this.checked = true
		}
	},
	mounted() {
		// 验证码初始化
		this.identifyCode = ''
		this.makeCode(this.identifyCodes, 4)
		this.$nextTick(() => {
			if (this.loginForm.username === '') {
				this.$refs.username.focus()
			} else if (this.loginForm.password === '') {
				this.$refs.password.focus()
			}
		});
		//监听键盘事件
		window.addEventListener("keydown", this.keyDown);
	},
	destroyed() { },
	methods: {
		//跳转首页
		requesthome() {
			this.$router.push('/loginHome');
		},
		//修改密码
		updatePassword() {
			this.$router.push('/updatePassword');
		},
		//登录
		checkLogin() {
			this.$refs.loginForm.validate(valid => {
				if (valid) {
					login(this.loginForm).then((result) => {
						if (result.status == 200) {
							let hostname = window.location.hostname;
							hostname = hostname.replace("www", "");
							setToken(result.data.token, {
								expires: 1,
								path: '',
								domain: hostname
							});
							if (this.$route.query.redirect) {
								//跳转
								let redirect = this.$route.query.redirect;
								this.$router.push(redirect);
								// 储存登录信息
								this.setUserInfo()
							} else {
								//跳转
								this.$router.push('/loginHome');
								// 储存登录信息
								this.setUserInfo()
							}
						} else {
							MessageBox.alert(result.message, '错误', {
								confirmButtonText: '确定',
								type: 'error'
							})
							return false
						}
					})
				}
			})
		},
		// 储存表单信息
		setUserInfo: function () {
			// 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
			// 如果没有勾选，储存的信息为空
			if (this.checked) {
				this.setCookie("username", this.loginForm.username)
				// base64加密密码
				let passWord = Base64.encode(this.loginForm.password)
				this.setCookie("password", passWord)
			} else {
				this.setCookie("username", "")
				this.setCookie("password", "")
			}
		},
		// 获取cookie
		getCookie: function (key) {
			if (document.cookie.length > 0) {
				var start = document.cookie.indexOf(key + '=')
				if (start !== -1) {
					start = start + key.length + 1
					var end = document.cookie.indexOf(';', start)
					if (end === -1) end = document.cookie.length
					return unescape(document.cookie.substring(start, end))
				}
			}
			return ''
		},
		// 保存cookie
		setCookie: function (cName, value, expiredays) {
			var exdate = new Date()
			exdate.setDate(exdate.getDate() + expiredays)
			document.cookie = cName + '=' + decodeURIComponent(value) +
				((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
		},
		// 生成随机数
		randomNum(min, max) {
			return Math.floor(Math.random() * (max - min) + min)
		},
		// 切换验证码
		refreshCode() {
			this.identifyCode = ''
			this.makeCode(this.identifyCodes, 4)
		},
		// 生成四位随机验证码
		makeCode(o, l) {
			for (let i = 0; i < l; i++) {
				this.identifyCode += this.identifyCodes[
					this.randomNum(0, this.identifyCodes.length)
				]
			}
			console.log(this.identifyCode)
		},
		//点击回车键登录
		keyDown(e) {
			// 回车则执行登录方法 enter键的ASCII是13
			if (e.keyCode == 13) {
				this.checkLogin(); // 定义的登录方法
			}
		}
	},
	destroyed() {
		//销毁事件
		window.removeEventListener("keydown", this.keyDown, false);
	}
};
</script>

<style scoped>
.login {
	width: 100%;
	height: 100vh;
	display: flex;
}

.login-left {
	width: 60%;
	height: 100%;
	background-color: #3E7EFF;
	padding-left: 2.1rem;
	padding-top: 1.6rem;
}

.logo {
	width: 17%;
	cursor: pointer;
}

.login-bg {
	margin-top: 17%;
	margin-left: 0.5rem;
	width: 87%;
}

.login-right {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-left: 7.2%;
	margin-right: 8.2%;
}

.right-form {
	box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 0.10);
	padding: 3.3rem 2.6rem 2.6rem;
	color: #303133;
	width: 100%;
	min-width: 400px;
	max-width: 450px;
}

.welcome {
	color: #303133;
	font-weight: 600;
	font-size: 1.14rem;
	margin-bottom: 1.7rem;
}

.day {
	font-size: 0.5rem;
}

.btn {
	margin-top: 1.9rem;
	width: 100%;
	background-color: #3E7EFF;
	border-radius: 16px;
	text-align: center;
	padding: 8px 9px;
	font-size: 0.7rem;
	letter-spacing: 2px;
	color: #fff;
}

.identifybox {
	display: flex;
	justify-content: space-between;
	margin-top: 7px;
}

.iconstyle {
	color: #409EFF;
}
</style>