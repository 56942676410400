import request from "@/utils/request";  //导入封装请求的js文件
  
export function pageList(data) {
	return request({
		url: '/SchoolManage/PageList',
		method: 'post',
		data
	})
}

export function login(data) {
	return request({
		url: '/Common/Login',
		method: 'post',
		data
	})
}

//修改密码
export function resetPassword(data) {
	return request({
		url: '/Common/resetPassword',
		method: 'post',
		data
	})
}